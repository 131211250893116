<!--充值套餐设置-->
<template>
  <div id="packageSetting">
    <div class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="输入套餐名称搜索"
          @input="kwChange"
          clearable=""
          class="mr20"
        />
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goExport()">充值码派发</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="套餐名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="赠送金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.giveAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="套餐状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageStatus == 0 ? "已下架" : "售卖中" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="充值码派发"
      :visible.sync="sendShow"
      :close-on-click-modal="false"
      append-to-body
      width="500px"
    >
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0"></div>
      <el-checkbox-group
        v-model="checkedPeople"
        @change="handleCheckedPeopleChange"
      >
        <el-checkbox
          v-for="people in peopleList"
          :label="people.advanceId"
          :key="people.advanceId"
          >{{ people.nickName }}</el-checkbox
        >
      </el-checkbox-group>
      <section class="form-footer">
        <el-button type="primary" @click="goConfirmSend()">确定</el-button>
      </section>
    </el-dialog>
    <!-- 导出进度弹框 -->
    <el-dialog
      title="充值码导出"
      :visible.sync="exportShow"
      center
      :close-on-click-modal="false"
      append-to-body
      width="20%"
    >
      <div class="export">
        <el-progress
          class="mb15 export-success"
          type="circle"
          text-color="#13CE66"
          :percentage="100"
          status="success"
          v-if="progressInfo.complete"
        ></el-progress>
        <el-progress
          class="mb15"
          type="circle"
          color="#EF9807"
          :percentage="percentage"
          :format="format"
          v-else
        ></el-progress>
        <p class="mb15">
          <b style="color: #13ce66" v-if="progressInfo.complete">已完成</b>
          <b style="color: #ef9807" v-else>进行中</b>
        </p>
        <el-button v-if="this.downloadUrl" @click="onDown">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPackageList,
  goDealQrcode,
  goDownloadQrcode,
  getQrcodeProgress,
} from "@/api/recharge/packageSetting.js";
import { getPeopleList } from "@/api/serve/serve";

export default {
  name: "PackageSetting",
  data() {
    return {
      tableData: [],
      tableParams: {
        kw: "",
        page: 1,
        pageSize: 10,
        merchantId: "",
        shopId: "",
      },
      total: 0,
      currentPage: 1,

      sendShow: false,
      isIndeterminate: false,
      checkAll: false,
      checkedPeople: [],
      peopleList: [],
      qrCodeNumber: 0,
      exportShow: false,
      percentage: 0,
      progressInfo: {
        complete: false,
        num: 0,
        total: 0,
      },
      downloadUrl: "",
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
    this.getPackageList();
    this.getPeopleList();
  },
  methods: {
    // 【监听】位置码下载
    onDown() {
      window.location.href = this.downloadUrl;
    },
    format() {
      return `${this.progressInfo.num}/${this.progressInfo.total}`;
    },
    // 处理位置码
    goDealQrcode() {
      let data = {
        shopId: this.tableParams.shopId,
        advanceIdSet: this.checkedPeople,

      };
      goDealQrcode(data).then((res) => {
        if (res.isSuccess == "yes") {
          
          this.$message(res.data);
          this.getQrcodeProgress(); //获取处理进度
        }
      });
    },
    // 获取处理进度
    getQrcodeProgress() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      getQrcodeProgress(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.progressInfo = res.data;
          this.percentage = Math.round((res.data.num / res.data.total) * 100);
          if (res.data.complete) {
            this.goDownloadQrcode();
          } else {
            setTimeout(() => {
              this.getQrcodeProgress();
            }, 1000);
          }
        }
      });
    },
    // 【请求】获取下载链接
    goDownloadQrcode() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      goDownloadQrcode(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.downloadUrl = res.data;
        }
      });
    },
    // 确定导出
    goConfirmSend() {
      if (this.checkedPeople.length == 0) {
        this.$message({
          message: "请至少选择一位推销员",
          type: "error",
        });
      } else {
        this.sendShow = false
        this.exportShow =true
        this.goDealQrcode();
      }
    },
    // 全选
    handleCheckAllChange(val) {
      if (val) {
        this.peopleList.forEach((item) => {
          this.checkedPeople.push(item.advanceId);
        });
      } else {
        this.checkedPeople = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedPeopleChange(value) {
      let checkedCount = value.length;
      if (checkedCount == this.checkedPeople.length) {
        this.checkAll = true;
      }
      if (checkedCount == 0) {
        this.checkAll = false;
      }
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.peopleList.length;
    },
    //  获取运营人员列表
    getPeopleList() {
      let data = {
        page: 1,
        pageSize: 0,
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      };
      getPeopleList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.peopleList = res.data.list;
        }
      });
    },
    // 打开弹框选择
    goExport() {
      this.checkedPeople = [];
      this.sendShow = true;
    },
    // 套餐列表
    getPackageList() {
      getPackageList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPackageList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPackageList();
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
#packageSetting {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
}
.export {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.form-footer {
  display: flex;
  justify-content: flex-end;
}

</style>